import React from "react";
import { Layout, SEO } from "../layout";
import { Link } from "gatsby";

const IndexPage = () => (
	<Layout>
		<SEO title="Home" />
		<section className="section-padding">
			<div className="container rhythm">
				<h2>
					We help non-profits, small businesses, and campaigns build
					and mobilize communities
				</h2>
				<p>
					<a href="http://www.bsmg.co" className="footerlink">
						Learn more about us ➩
					</a>
				</p>
			</div>
		</section>
	</Layout>
);

export default IndexPage;
